export default defineNuxtRouteMiddleware(async (to, from) => {
  if (to.params.deviceId && to.params.manufacturerId) {
    const { deviceId, manufacturerId } = to.params;
    try {
      const data = await getDevices({
        manufacturer_uri_id: manufacturerId as string,
        uri_id: deviceId as string,
        page: 1,
        size: 1,
      });
      if (data.count < 1) {
        return navigateTo('/not-found');
      }
      const deviceState = useState('deviceByUri', () => data.results[0]);
      deviceState.value = data.results[0];
      const manufacturerState = useState('manufacturer', () => data.results[0].manufacturer);
      manufacturerState.value = data.results[0].manufacturer;
    } catch (error) {
      if (error.response && error.response.status === 404) {
        return navigateTo('/not-found');
      }
    }
  }
});
